import { NextSeoProps } from 'next-seo';
import { LandingPage } from '../lib/strapi';

export const extractSeoProps = (content: LandingPage, currentRoute = '') => {
  const isSubdomainSite = content.domain?.includes('leadquelle.net');
  const disallowGoogleIndex = content.google_allow_indexation === false;

  let title = content.seo_title;
  let description = content.seo_description;

  if (currentRoute === '/fragebogen') {
    title = `Questionnaire Section - ` + title;
    description = `Questionnaires Section - ` + description;
  } else if (currentRoute.includes('/fragebogen/')) {
    title = `Questionnaire (${currentRoute.split('/')[2]}) - ` + title;
    description =
      `Questionnaires (${currentRoute.split('/')[2]}) - ` + description;
  }

  return {
    title: title,
    description: description,
    defaultTitle: content.seo_title,
    noindex: disallowGoogleIndex || isSubdomainSite ? true : false,
    nofollow: disallowGoogleIndex || isSubdomainSite ? true : false,
    openGraph: {
      type: 'website',
      url: `https://${content.domain}`,
      description: content.seo_description,
      title: content.seo_title,
    },
    additionalLinkTags: content.favicon?.data?.attributes
      ? [
          {
            rel: 'shortcut icon',
            type: content.favicon?.data.attributes.mime,
            href: content.favicon?.data.attributes.url,
          },
          {
            rel: 'shortcut icon',
            sizes: '192x192',
            href: content.favicon?.data.attributes.url,
          },
          {
            rel: 'apple-touch-icon',
            href: content.favicon?.data.attributes.url,
          },
        ]
      : undefined,
  } as NextSeoProps;
};
