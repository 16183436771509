function hexToRgb(hex: string): { r: number; g: number; b: number } {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

function luminance({ r, g, b }: { r: number; g: number; b: number }): number {
  // Convert RGB to sRGB
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  // Calculate luminance
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrastRatio(hex1: string, hex2: string): number {
  const lum1 = luminance(hexToRgb(hex1));
  const lum2 = luminance(hexToRgb(hex2));

  // Calculate the contrast ratio
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  return (brightest + 0.05) / (darkest + 0.05);
}

export function chooseBetterContrastColor(
  fixedColor: string,
  color1: string,
  color2: string,
  color3: string,
): string {
  const contrast1 = contrastRatio(fixedColor, color1);
  const contrast2 = contrastRatio(fixedColor, color2);
  const contrast3 = contrastRatio(fixedColor, color3);

  if (contrast1 > contrast2 && contrast1 > contrast3) {
    return color1;
  } else if (contrast2 > contrast1 && contrast2 > contrast3) {
    return color2;
  } else {
    return color3;
  }
}
